// material
import { Typography, Box, Grid, Paper } from "@material-ui/core";
import { Container, Link, styled } from "@mui/material";
//
import { varFadeIn, MotionInView, varFadeInRight } from "components/animate";

// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));
// ----------------------------------------------------------------------

interface CategoryCardProps {
  label?: string;
  icon?: string;
  description?: string;
  href?: string;
}

function CategoryCard({ label, icon, description, href }: CategoryCardProps) {
  return (
    <Paper
      component={Link}
      href={href}
      target="_blank"
      sx={{
        p: 2,
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
        "&:hover": {
          boxShadow: (theme) => theme.customShadows.z8,
          ...(href && {
            cursor: "pointer",
          }),
        },
        textDecoration: "none",
      }}
      variant="outlined"
    >
      {icon && (
        <Box
          component="img"
          src={icon}
          sx={{
            height: 90,
          }}
        />
      )}
      {label && <Typography variant="subtitle1">{label}</Typography>}
      {description && (
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      )}
    </Paper>
  );
}

interface CardListProps {
  title: string;
  categories: CategoryCardProps[];
}

export default function CardList({ title, categories }: CardListProps) {
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <MotionInView variants={varFadeInRight}>
            <Typography variant="h2" sx={{ mb: 3 }}>
              {title}
            </Typography>
          </MotionInView>
          <Grid
            item
            container
            spacing={6}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {categories.map((category) => (
              <Grid item xs={12} sm={4} md={6} lg={4} key={category.label}>
                <MotionInView variants={varFadeIn}>
                  <CategoryCard {...{ ...category }} />
                </MotionInView>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
