import { Navigate, useRoutes } from "react-router-dom";
// layouts
import MainLayout from "layouts/main";
import Home from "./../screens/home/index";
import Informative from "screens/informative";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/informativos",
          element: <Informative />,
        },
        {
          path: "*",
          element: <Navigate to="/" />,
        },
      ],
    },
  ]);
}
