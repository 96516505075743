import { Icon } from "@iconify/react";
import { Button, Link, Stack, Typography, styled } from "@mui/material";

const RootStyle = styled(Stack)(({ theme }) => ({
  textAlign: "center",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(5),
  flexDirection: "column",
  gap: 5,
}));

const WhatsappSection = () => {
  return (
    <RootStyle
      sx={{ padding: 4, backgroundColor: "primary.lighter" }}
      alignItems="center"
      justifyContent="center"
      spacing={5}
      marginTop={10}
      marginBottom={5}
    >
      <Stack direction="column" spacing={3}>
        <Typography variant="h3" color="primary.darker">
          Grupo no Whatsapp
        </Typography>
        <Typography variant="body1" color="primary.dark">
          Que tal ficar por dentro das informações sobre <br /> os leilões do
          Governo Federal ?
        </Typography>
      </Stack>
      <Button
        component={Link}
        target="_blank"
        href="https://chat.whatsapp.com/BuL4LMR5Pe5EucHHFd0VbT"
        endIcon={<Icon icon="mdi:whatsapp" width={45} height={45} />}
        variant="contained"
      >
        <Typography variant="h6" noWrap>
          Entrar no grupo
        </Typography>
      </Button>
    </RootStyle>
  );
};

export default WhatsappSection;
