import { motion } from "framer-motion";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Container, Typography } from "@material-ui/core";
//
import { varWrapEnter, varFadeInRight, TextAnimate } from "components/animate";

interface AboutHeroProps {
  image: string;
  text1: string;
  text2: string;
  text3: string;
  description?: string;
}

export default function AboutHero({
  text1,
  text2,
  text3,
  description,
  image,
}: AboutHeroProps) {
  // ----------------------------------------------------------------------

  const RootStyle = styled(motion.div)(({ theme }) => ({
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(/static/overlay.svg), url(${image})`,
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up("md")]: {
      height: 560,
      padding: 0,
    },
  }));

  const ContentStyle = styled("div")(({ theme }) => ({
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      position: "absolute",
      bottom: theme.spacing(10),
    },
  }));

  // ----------------------------------------------------------------------
  return (
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
      <Container maxWidth="lg" sx={{ position: "relative", height: "100%" }}>
        <ContentStyle>
          {text1.split(" ").map((word) => (
            <>
              <TextAnimate
                text={word}
                sx={{ color: "primary.main", mr: 3 }}
                variants={varFadeInRight}
              />
            </>
          ))}
          <br />
          <Box sx={{ display: "inline-flex", color: "common.white" }}>
            {text2.split(" ").map((word) => (
              <>
                <TextAnimate text={word} sx={{ mr: 3 }} />
              </>
            ))}
            <TextAnimate text={text3} />
          </Box>

          {description && (
            <motion.div variants={varFadeInRight}>
              <Typography
                variant="h4"
                sx={{
                  mt: 5,
                  color: "common.white",
                  fontWeight: "fontWeightMedium",
                }}
              >
                {description}
              </Typography>
            </motion.div>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
