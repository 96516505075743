import { Outlet } from "react-router-dom";
// material
import { Stack } from "@material-ui/core";
// components
import MainFooter from "./MainFooter";
import MainNavbar from "./MainNavbar";

// ----------------------------------------------------------------------

export default function MainLayout() {
  return (
    <Stack direction="column">
      <MainNavbar />

      <div style={{ minHeight: "80vh" }}>
        <Outlet />
      </div>

      <MainFooter />
    </Stack>
  );
}
