import { Icon } from "@iconify/react";
import homeFill from "@iconify/icons-eva/home-fill";
import roundGrain from "@iconify/icons-ic/round-grain";
import bookOpenFill from "@iconify/icons-eva/book-open-fill";
import { PATH_APP } from "routes/paths";
// routes

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "Sobre",
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: PATH_APP.about,
  },
  {
    title: "Informativos",
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    path: PATH_APP.informative,
  },
];

export default menuConfig;
