import AboutHero from "../../../adapted-components/AboutHero";
import AboutWhat from "../../../adapted-components/AboutWhat";
import CardList from "../../../adapted-components/CardList";
import WhatsappSection from "../components/WhatssapSection";
import { RootStyle } from "./styles";

const serviceList = [
  {
    label: "Corretagem",
    icon: "https://media-public.canva.com/VFuA4/MAFVfvVFuA4/1/tl.png",
    description: "Intermediação para compra e venda de grãos",
  },
  {
    label: "Comercialização",
    icon: "https://media-public.canva.com/z7hOQ/MAENEwz7hOQ/1/tl.png",
    description: "Compra e venda de soja e milho",
  },
  {
    label: "Consultoria",
    icon: "https://media-public.canva.com/MADpjtkVAWY/1/thumbnail_large.png",
    description: "Armazenamento e classificação de grãos",
  },
  {
    label: "Classificação",
    icon: "https://media-public.canva.com/zmgm4/MACLsPzmgm4/4/t.png",
    description:
      "Credenciado no MAPA para os seguintes produtos: Arroz, Feijão, Girassol, Milho, Milho Pipoca, Castanha do Brasil, Canjica e Soja",
  },
  {
    label: "Leilões",
    icon: "https://media-public.canva.com/LzSSE/MAC6mdLzSSE/2/t.png",
    description: "Referente aos mecanismos de apoio a comercialização Agricula",
  },
  {
    label: "Mais",
    icon: "https://media-public.canva.com/MADnBguAWRY/1/thumbnail.png",
    description: "Entre em contato para mais informações",
  },
];

const auctionsList = [
  {
    label: "PEPRO",
    href: "https://www.conab.gov.br/comercializacao/leiloes-publicos/pepro",
  },
  {
    label: "PEP",
    href: "https://www.conab.gov.br/comercializacao/leiloes-publicos/pep",
  },
  {
    label: "Contrato de Opção",
    href: "https://www.conab.gov.br/comercializacao/leiloes-publicos/contrato-de-opcao-de-compra-publico",
  },
  {
    label: "Frete",
    href: "https://www.conab.gov.br/estoques/remocao-de-estoques/contratacao-de-fretes",
  },
  {
    label: "Leiloes de Compra",
    href: "https://www.conab.gov.br/comercializacao/leiloes-publicos/compra-publica",
  },
  {
    label: "Leilões de Trocas - Cesta básica",
    href: "https://www.conab.gov.br/comercializacao/leiloes-publicos/troca-publica",
  },
];
const brokerageList = [
  {
    icon: "/logos/rural_norte_logo.svg",
    href: "http://www.ruralnorte.com.br/",
  },
];

const View: React.FC = () => {
  return (
    <RootStyle>
      <AboutHero
        {...{
          image: "/static/images/screen_2x.jpg",
          text1: "Quem",
          text2: "nós",
          text3: "somos?",
        }}
      />
      <AboutWhat
        {...{
          image1: "/static/images/sobre-graficos.svg",
          image2: "/static/images/sobre.svg",
          title: "Sobre",
          description: `Genuinamente sinopense, a empresa BMCS - Bolsa De Mercadorias e Cereais de Sinop,
          atua desde 2002, trazendo para a
          região serviços imprescindíveis para o seguimento do
          agronegócio. A Bolsa de mercadorias mantêm contrato com o
          Governo Federal por intermédio da CONAB para a realização de
          todos os Leilões ligados aos instrumentos públicos pertinentes a
          Política Agrícola Nacional.`,
        }}
      />
      <CardList {...{ title: "Serviços", categories: serviceList }} />
      <CardList {...{ title: "Leilões", categories: auctionsList }} />
      <CardList
        {...{ title: "Nossas corretoras", categories: brokerageList }}
      />
      <WhatsappSection />
    </RootStyle>
  );
};

export default View;
