export const PATH_APP = {
  about: "/",
  informative: "/informativos",
}


export const PATH_PAGE = {
  home: "/home",
  checkout: "/checkout",
  declaration: "/declaracao",
  selectProduct: "/escolher-produto",
  products: "/produtos",
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};
