import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import { RootStyle } from "./styles";
import {
  Button,
  Card,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";

const View = () => {
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          sx={{ mt: 5 }}
          {...{
            heading: "Informativos",
            links: [{ name: "Home" }, { name: "Informativos" }],
          }}
        />
        <Grid
          container
          direction="row"
          spacing={6}
          sx={{ padding: 2 }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={6}>
            <img
              alt="Informativo"
              src="/static/images/alerta_mercado.jpg"
              style={{ borderRadius: "5px", maxHeight: 400 }}
            />
          </Grid>
          <Grid
            item
            container
            spacing={2}
            xs={12}
            sm={6}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                ⚠ ALERTA DE MERCADO <br />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary">
                🌽Preço Mínimo do Milho em MT/RO é de R$ 43,26 por saca de 60
                kg, conforme Portaria do Governo nº 452/22, datada de 04 de
                julho de 2022.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                👉Há possibilidade de realização de leilões de milho. Portanto,
                recomendamos que antes de fechar contratos de venda, exija
                cláusulas que obriguem a entrega de documentos para comprovação
                do leilão.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                🥳Não perca esta oportunidade, entre em contato conosco agora
                mesmo!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                component={Link}
                href="https://chat.whatsapp.com/BuL4LMR5Pe5EucHHFd0VbT"
                target="_blank"
                variant="contained"
              >
                Entrar em Contato
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
};

export default View;
