import { NavLink as RouterLink, NavLinkProps } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { AppBar, Toolbar, Container } from "@material-ui/core";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
// components
import { MenuHidden } from "../../components/@material-extend";
//
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import navConfig from "./MenuConfig";
import { Box, IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import { PATH_APP } from "routes/paths";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: APP_BAR_DESKTOP,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: "FaceBook",
    icon: "logos:facebook",
    link: "https://www.facebook.com/profile.php?id=100092364730332",
  },
  {
    name: "Instagram",
    iconString: "skill-icons:instagram",
    link: "https://www.instagram.com/bmcs_bolsa_de_mercadorias_/",
  },
  {
    name: "Whatsapp",
    iconString: "bi:whatsapp",
    link: "https://api.whatsapp.com/send/?phone=556635311798&text&app_absent=0",
  },
];

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: NavLinkProps;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const isHome = false;

  return (
    <AppBar
      sx={{
        boxShadow: "0 2px 2px #00000026",
        bgcolor: "white",
        boxSizing: "border-box",
        overflow: "hidden",
        height: "auto",
      }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: "background.default",
            height: { md: APP_BAR_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RouterLink to={PATH_APP.about}>
            <img
              width={170}
              src="logos/logo_bmcs_sem_texto.svg"
              alt="Logo BMCS"
            />
          </RouterLink>

          <MenuHidden width="xsDown">
            <Box>
              {SOCIALS.map((social: any) => (
                <IconButton
                  href={social.link}
                  target="blank"
                  key={social.name}
                  color="primary"
                  sx={{ p: 1 }}
                >
                  <Icon icon={social.icon || social.iconString} width={25} />
                </IconButton>
              ))}
            </Box>
          </MenuHidden>

          <MenuHidden width="mdDown">
            <MenuDesktop
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MenuHidden>

          <MenuHidden width="mdUp">
            <MenuMobile
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MenuHidden>
        </Container>
      </ToolbarStyle>
      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
