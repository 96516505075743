import { Icon } from "@iconify/react";
// material
import { styled } from "@material-ui/core/styles";
import {
  Link,
  Divider,
  Container,
  Typography,
  IconButton,
  Stack,
} from "@material-ui/core";
import { useLocation } from "react-router";
import { Box } from "@mui/material";
// routes
// ----------------------------------------------------------------------

export const SOCIALS = [
  {
    name: "FaceBook",
    iconString: "logos:facebook",
    link: "https://www.facebook.com/profile.php?id=100092364730332",
  },
  {
    name: "Instagram",
    iconString: "skill-icons:instagram",
    link: "https://www.instagram.com/bmcs_bolsa_de_mercadorias_/",
  },
  {
    name: "Whatsapp",
    iconString: "bi:whatsapp",
    link: "https://api.whatsapp.com/send/?phone=556635311798&text&app_absent=0",
  },
];

const RootStyle = styled("div")(({ theme }) => ({
  backgroundColor: "#000",
  boxSizing: "border-box",
  width: "100%",
}));

// ----------------------------------------------------------------------

export default function MainFooter() {

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ height: "100%" }}>
        <Stack
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2, mb: 5 }}
        >
          <Typography variant="h2" color="white">
            BMCS
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Crescendo com Sinop e Região
          </Typography>
          <Box>
            {SOCIALS.map((social) => (
              <IconButton
                component={Link}
                href={social.link}
                target="blank"
                key={social.name}
                color="primary"
                sx={{ p: 1 }}
              >
                <Icon icon={social.iconString} width={25} />
              </IconButton>
            ))}
          </Box>
          <Typography
            component="p"
            variant="body2"
            color="white"
            sx={{
              pt: 5,
              fontSize: 13,
              textAlign: { xs: "center", md: "center" },
            }}
          >
            © 2023. Todos os direitos reservados - BMCS - Bolsa de Mercadorias e
            Cereais de Sinop
          </Typography>
        </Stack>
      </Container>
      <Divider />
    </RootStyle>
  );
}
