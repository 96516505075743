import { styled, Card } from "@mui/material";

export const RootStyle = styled("div")(({ theme }) => ({
  marginTop: 88,
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    marginTop: 64,
  },
  marginBottom: 30,
}));
